.myInput{
    padding: 0.6vw 1.8vw;
    background: #ebf2f7;
    border: 0.2vw solid #9da7eb;
    border-radius: 0.8vw;
    color: #000;
    font-size: 1.3vw;
    font-weight: normal;
    padding: 0 20px;
    width: 70vw;
    height: 3.5vw;
    box-shadow: 0.05vw 0.05vw 0.3vw rgba(0, 0, 0, 0.411);
    transition: all 0.6s linear;
    margin-bottom: 30px;
}

.myInput:hover{
    background: #e3ebef;
    box-shadow: 0.05vw 0.05vw 0.5vw rgba(0, 0, 0, 0.411);
}