.mySearch{
    background: #e3ebf0;
    border: 0.12vw solid #e8f1f8;
    border-radius: 0.4vw;
    color: #000;
    font-size: 1.2vw;
    font-weight: normal;
    padding: 0.4vw 1.5vw;
    max-width: 50vw;
    box-shadow: 0.1vw 0.1vw 0.2vw #777eaf;;
    margin-right: 1.2vw;
    transition: all 0.3s linear;
}

.mySearch:hover{
    background: #e3ebef;
    box-shadow: 0.1vw 0.1vw 0.4vw #777eaf;
}