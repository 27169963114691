.work-enter {
    opacity: 0;
}
.work-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.work-exit {
    opacity: 1;
}
.work-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}