.modal-active{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.596);
    width: 100%;
    height: 100%;
}

.modal-hidden{
    display: none 
}

.modal-content {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    color: #000000;
    margin-bottom: 15px;
}

.modal-text {
    font-size: 1em;
    color: #333;
    margin-bottom: 20px;
}

.modal-link {
    font-size: 1em;
    color: #007bff;
    text-decoration: underline;
    word-break: break-word;
    display: block;
    margin-bottom: 20px;
}

.modal-close-btn {
    padding: 10px 20px;
    background-color: #8d96d2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.modal-close-btn:hover {
    background-color: #7a82b3;
}
