.file-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    border: 0.25vw solid #9da7eb;
    border-radius: 1vw;
    background-color: #f2f6ff;
}
  
.file-input {
    font-size: 1.1vw;
    padding: 1vw;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #9da7eb;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.file-input:hover {
    background-color: #767eb4;
}
  
.file-names {
    font-size: 1.1vw;
    text-align: left;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}
  
.file-names p {
    margin: 0.1vw 0;
    font-size: 1.1vw;
    color: #333;
}
  