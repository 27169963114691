.work-Item{
    border: 0.2vw solid #ced3d7;
    border-radius: 1vw;
    transition: all 0.3s linear;
    cursor: pointer;
}

.work-Item:hover{
    box-shadow: 0.1vw 0.1vw 0.3vw #c2c5c7;
}

.work-Item:not(:last-child){
    margin-bottom: 1.2vw;
}

.work-Item-body{
    padding: 3vw;
}

.workItem__title{
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1vw;
    font-weight: 500;
    font-size: 1.4vw;
}

.workItem__text{
    font-size: 0.9vw;
}