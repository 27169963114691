
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}
  

html, body {
    font-family: Arial, sans-serif;
    line-height: 1.6; 

}

a {
    text-decoration: none;
    color: inherit; 
}

button, input, textarea {
    border: none;
    outline: none;
    background: none;
    font-family: inherit;
}
  
table {
    border-collapse: collapse;
    width: 100%;
}
  
button {
        cursor: pointer; 
}

body {
    font-family: "Mulish", sans-serif;
    background-color: #F2F6FF;
}

h1, h2, h3 {
    font-family: "Playwrite CU", serif
}

.container {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0px 15px 0px 15px;
}

@media(max-width: 1200px){
    .container{
        max-width: 1000px;
    }
}

@media(max-width: 1000px){
    .container{
        max-width: 768px;
    }
}

@media(max-width: 768px){
    .container{
        max-width: 550px;
    }
}

.emptyposts{
    margin: 0 auto;
}

/*----------header-------------*/

.works-header{
    /* background-color: #3a3f58e5;  */
    background-color: #f2f4fb; 
    box-shadow: 0.1vw 0.1vw 0.3vw #23252f55;
    width: 100%;
    text-align: center;
    margin-bottom: 2vw;
}

.works-header__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.works-header__body{
    display: flex;
    justify-content: flex-end;
}

.works-header nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.works-header__title {
    font-size: 2vw;
    color:  rgb(53, 53, 141) ;
    font-family: "Playwrite CU", serif;
    cursor: pointer
}

@media(max-width: 480px){
    .works-header{
        padding: 1vw 2vw;
    }
    .title {
        font-size: 3vw;
    }
    .app-button{
        font-size: 2vw
    }
}

.works-header a {
    font-size: 1.3vw;
    color: #777eaf;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s linear;
}

.works-header a:not(:last-child){
    margin-right: 1.2vw;
}

.works-header a:hover {
    color: #1a8fe3;
}

/*--------works-page-----------*/

.works-page__input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.works-page__title{
    font-size: 2.3vw;
    text-align: center;
    font-weight: 600;
    color: rgb(49, 74, 90);
    margin-bottom: 1.5vw;
}

.works-page-content{
    display: flex;
}

.works-page-content-filter{
    flex: 0 1 30%;
    align-self: flex-start;
}

.works-page-content__works{
    display: flex;
    flex-direction: column;
    flex: 0 1 70%;
    justify-content: center;
    align-items: space-between;
}

.works-page-content__works-scroll {
    max-height: 72.5vh; 
    overflow-y: auto; 
    padding-bottom: 1vw; 
    box-sizing: border-box;
    padding: 0; 
    border: none; 
    width: 100%; 
}

.works-page-content__works-scroll::-webkit-scrollbar {
    display: none;
}
.works-page-content__works-scroll {
    scrollbar-width: none; 
}

.works-page__body {
    overflow: hidden;
}

.works-page-content-filter{
    padding: 1.5vw;
    border: 0.2vw solid #9da7eb;
    box-shadow: 0.1vw 0.1vw 0.2vw #4a517155;
    border-radius: 0.8vw;
    margin-right: 3vw;
    transition: all 0.1s linear;
}

.works-page-content-filter__items{
    color: rgb(49, 74, 90);
}

.works-page-content-filter__title{
    font-size: 1.7vw;
    margin-bottom: 1.3vw;
    color: #444;
}

.works-page-content-filter hr{
    max-height: 1vw;
}


/*--------works-id-page-----------*/

.work-id-page{
    display: flex;
    justify-content: center;  
    align-items: center;    
}

.work-id-page-body{
    display: inline-block;  
    border: 0.2vw solid #9da7eb;
    padding: 2rem;        
    background-color: #f2f6ff;  
    border-radius: 10px;      
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    min-width: 50vw;
    max-width: 50vw;
}

.work-id-page-body__title{
    font-size: 2vw;
    font-family: 'Roboto', sans-serif;
}

.work-id-page-body__text{
    font-size: 1.1vw;
}

.work-id-page-body :not(:last-child){
    margin-bottom: 1vw;
}

.file-item {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;    
    padding: 1vw;        
    border: 0.15vw solid #dddddd; 
    border-radius: 10px;    
    background-color: #9ea6f01a; 
    max-width: 25vw;  
    box-shadow: 0 0.1vw 0.3vw rgba(0, 0, 0, 0.1); 
}


.file-icon {
    max-width: 100%;
    height: auto;   
    border-radius: 8px; 
    object-fit: cover; 
}

.work-id-page-wrapper{
    padding: 0.8vw;
}

.button-behind{
    background-color: #777eaf;
    color: aliceblue;
    padding: 0.8vw;
    font-size: 1.1vw;
    font: bold;
    border-radius: 1.2vw;
}

.file-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}

.download-button {
    font-size: 1.3vw;
    padding: 0.8vw 2vw;
    background-color: #8d96d2;
    color: white;
    text-decoration: none;
    border-radius: 1vw;
    text-align: center;
    transition: all 0.3s linear;
}

.download-button:hover {
    background-color: #7a82b3;
}

@media(max-width: 720px){
    .button-behind{
        padding: 1vw;
        font-size: 1.2vw;
    }
    .work-id-page-body{
        padding: 1.2rem;        
    }
}

@media(max-width: 480px){
    .button-behind{
        padding: 1.1vw;
        font-size: 1.3vw;
    }
    .work-id-page-body{
        padding: 1rem;        
    }
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    max-width: 100%;
    max-height: 100%;
    overflow: auto; 
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-overlay:hover {
    cursor: pointer;
}

body.no-scroll {
    overflow: hidden;
}
/*---------create-work-page------------*/

.create-work-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create-work-page__title{
    font-size: 2vw;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 2.5vw;
}

.create-work-page__body{

}

.create-work-page-body__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.create-work-page-body__content :not(:last-child){
    margin-bottom: 2vw;
}