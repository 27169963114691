.styled-select-container {
    font-family: Arial, sans-serif;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  
.select-label {
    font-size: 1.3vw;
    display: block;
    color: #333;
    margin-right: 1.5vw;
}
  
.styled-select {
    padding: 1vw 1vw;
    border: 0.2vw solid #9da7eb;
    border-radius: 0.5vw;
    background-color: #f2f6ff;
    font-size: 1.3vw;
    color: #333;
    cursor: pointer;
    transition: border-color 0.3s ease;
}
  
.styled-select:focus {
    border-color: #767eb4;
    outline: none;
}
